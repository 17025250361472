import '../styles/main.scss';

import Close from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { uniq } from 'lodash';
import TextField from 'components/ui-components-v2/TextField';
import InlineAlert from 'components/ui-components/InlineAlert';

/**
 * Item list
 * This allows you to input a list of items. It adds new values along the way.
 */
export class ItemList extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** Currently active language */
        language: PropTypes.string,
        /** Whether this is a multilanguage field */
        multiLanguage: PropTypes.bool,
        /** Whether this is a multiline input */
        multiline: PropTypes.bool,
        /** Whether we remove duplicate */
        removeDuplicate: PropTypes.bool,
        /** The maximum string length */
        maxLength: PropTypes.number,
        /** Minimum number of items */
        minItems: PropTypes.number,
        /** Maximum number of items */
        maxItems: PropTypes.number
    };

    static defaultProps = {
        onMutation: () => {},
        multiLanguage: false,
        multiline: false,
        removeDuplicate: false,
        minItems: 0,
        maxItems: 1000
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.state.value = this.returnValueFromInput(props.value, props.multiLanguage, props.language);
    }

    /**
     * On update check whether the language changed
     * @param {*} prevProps
     * @param {*} prevState
     */
    componentDidUpdate(prevProps, prevState) {
        const { language, multiLanguage, value } = this.props;
        if (prevProps.language != language) {
            this.setState({
                value: this.returnValueFromInput(value, multiLanguage, language)
            });
        }
    }

    /**
     * Get value that is active from input
     */
    returnValueFromInput = (input, multiLanguage, language) => {
        const { maxItems } = this.props;

        // Multi language, this has a value per language
        if (multiLanguage) {
            if (!input || !input[language]) {
                return [''];
            } else {
                return [...input[language], ''];
            }
        }
        // We can add more
        else if (!input || input.length < maxItems) {
            return input ? [...input, ''] : [''];
        } else {
            return input;
        }
    };

    /**
     * Remove an item from the list
     */
    onRemoveClicked = (index) => {
        const { value } = this.state;
        value.splice(index, 1);
        this.setState({ value: [...value] }, this.updateModel);
    };

    /**
     * Text changes, also update source
     */
    onTextChange = (event, index) => {
        const { maxItems } = this.props;
        const { value } = this.state;
        value[index] = event.target.value;
        const newValue = value;

        // Add new empty line in case we are adding a new one to the bottom
        if (index === value.length - 1 && value.length < maxItems) {
            newValue.push('');
        }

        this.setState(
            {
                value: [...newValue]
            },
            this.updateModel
        );
    };

    /**
     * Update the model
     */
    updateModel = () => {
        const { value, language, onMutation, multiLanguage, removeDuplicate } = this.props;
        let valueList = this.state.value.filter((x) => x !== '');

        // Remove duplicates
        if (removeDuplicate) {
            valueList = uniq(valueList);
        }

        let newValue = value;

        // Create new output
        if (multiLanguage) {
            if (!newValue) {
                newValue = {};
            }
            newValue[language] = valueList;
        } else {
            newValue = valueList;
        }
        onMutation(newValue);
    };

    render() {
        const { value = [] } = this.state;
        const { multiline, minItems, maxLength } = this.props;

        return (
            <div className="input__item-list">
                {minItems > 0 && this.props.value && this.props.value.length < minItems ? (
                    <InlineAlert type="error" message={'You have to add at least ' + minItems + ' items to the list'} />
                ) : (
                    ''
                )}
                {value.map((x, index) => (
                    <span className="input__item-list__item" key={'list_' + index}>
                        <TextField
                            fullWidth={true}
                            key={index}
                            margin="dense"
                            variant="outlined"
                            value={x}
                            onChange={(event) => this.onTextChange(event, index)}
                            multiline={multiline}
                            inputProps={{ maxLength: maxLength }}
                        />
                        <Close className="input__item-list__item__icon-close" onClick={() => this.onRemoveClicked(index)} />
                    </span>
                ))}
            </div>
        );
    }
}

export default ItemList;
