import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Axios from 'axios';
import { FormControlLabel, FormGroup } from '@mui/material';
import TextField from 'components/ui-components-v2/TextField';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Select from 'components/ui-components-v2/Select';
import Request from 'components/data/Request';
import EditorData from 'components/editor-data/EditorData';
import Loader from 'components/ui-components/Loader';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import InlineAlert from 'components/ui-components/InlineAlert';
import '../styles/main.scss';
import PublishHelpers from 'components/data/PublishHelpers';

/**
 * Item list
 * This allows you to input a list of items. It adds new values along the way.
 */
export class GoogleAds extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        showCampaignSelector: PropTypes.bool,
        showAdGroupSelector: PropTypes.bool,
        newAdGroup: PropTypes.bool,
        googleAdsAccountByMarket: PropTypes.any,
        googleAdsAccount: PropTypes.any,
        campaignStatus: PropTypes.string
    };

    static defaultProps = {
        onMutation: () => {},
        showCampaignChecklist: false,
        showCampaignSelector: true,
        showAdGroupSelector: true,
        newAdGroup: true
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : {},
            loading: true
        };

        // Accountid
        const market = EditorData.get('market');
        if (props.googleAdsAccountByMarket) {
            this.state.accountId = 0;
            if (props.googleAdsAccountByMarket) {
                this.state.accountId = props.googleAdsAccountByMarket[market];
            }
        }
        // Account id
        else if (props.googleAdsAccount) {
            this.state.accountId = props.googleAdsAccount;
        }
        // Get from setup
        else {
            this.state.accountId = PublishHelpers.getGoogleAdsAccountId();
        }
    }

    componentDidMount() {
        this.getToken();
    }

    /**
     * Fetch a token to connect to the publish api
     */
    getToken() {
        Request.post('publishing/getToken', { type: 'metadata' }).then((result) => {
            if (!result.success) {
                SnackbarUtils.error("We coulnd't connect you to the Google Ads API.");
                this.setState({ loading: false });
                return;
            }
            this.token = result.data.token;
            this.requestCampaigns();
        });
    }

    /**
     * Component did update
     * @param {*} prevProps
     * @param {*} prevState
     * @param {*} snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { onMutation } = this.props;

        if (prevProps.googleAdsAccount && prevProps.googleAdsAccount != this.props.googleAdsAccount) {
            this.setState({ loading: true, accountId: this.props.googleAdsAccount, value: {} }, this.requestCampaigns);
            onMutation({});
        }
    }

    /**
     * Request campaigns from the API
     */
    requestCampaigns() {
        const { value, accountId = '' } = this.state;
        const { showAdGroupSelector, campaignStatus } = this.props;
        const accountIdStripped = accountId.replace(/-/g, '');

        Axios.post(process.env.PUBLISH_ENGINE + 'V2/metadata/googleads/overview', {
            token: this.token,
            type: 'campaigns',
            status: campaignStatus,
            customerId: accountIdStripped
        }).then((response) => {
            if (response.data && !response.data.error && !response.data[0].error) {
                this.setState({ campaignsList: response.data, loading: false, adGroupsList: false });
                if (value && value.campaign) {
                    if (showAdGroupSelector) {
                        this.loadAdGroups(value.campaign);
                    }
                }
            } else {
                this.setState({ loading: false });
            }
        });
    }

    /**
     * Value changed
     * Send to onMutation
     */
    handleCampaignChanged = (event) => {
        const { onMutation, showAdGroupSelector } = this.props;

        const value = this.state.value;
        value.campaign = event.target.value;
        this.setState({ value: value });
        onMutation(value);

        if (showAdGroupSelector) {
            this.loadAdGroups(value.campaign);
        }
    };

    /**
     * Check a campaign
     * @param {*} campaign
     */
    handleCampaignChecked = (event) => {
        const { onMutation } = this.props;

        let value = this.state.value;
        if (!Array.isArray(value)) {
            value = [];
        }

        // Remove existing value
        let found = false;
        for (var index in value) {
            if (value[index] && value[index].resourceName == event.target.value) {
                value.splice(index, 1);
                found = true;
            }
        }

        // Add new
        if (!found) {
            const campaignsList = this.state.campaignsList;
            for (var index in campaignsList) {
                if (campaignsList[index].campaign.resourceName == event.target.value) {
                    value.push(campaignsList[index].campaign);
                }
            }
        }

        this.setState({ value: value });
        onMutation(value);
    };

    /**
     * Load Adgroups
     * This fetches the adgroups after selecting a campaign
     * @param {*} campaign
     */
    loadAdGroups(campaign) {
        this.setState({ loadingAdGroups: false });

        Axios.post(process.env.PUBLISH_ENGINE + 'V2/metadata/googleads/overview', {
            token: this.token,
            type: 'adGroups',
            campaign: campaign,
            customerId: this.state.accountId
        }).then((response) => {
            if (response.data && !response.data.error && response.data[0] && !response.data[0].error) {
                this.setState({ adGroupsList: response.data, loadingAdGroups: false }, this.selectAdGroup);
            } else {
                this.setState({ loadingAdGroups: false, adGroupsList: false });
            }
        });
    }

    /**
     * Loop through adgroups and check wheter we need to select an active value
     * This is used for a 'new adgroup' setup, and the adgroup exists after opening.
     */
    selectAdGroup() {
        const { adGroupsList, value } = this.state;
        const { onMutation } = this.props;

        if (!value.adGroup || value.adGroup != 'new') {
            return;
        }

        // Find existing adgroup
        let foundAdGroup;

        if (adGroupsList.forEach) {
            adGroupsList.forEach((item) => {
                if (value.newAdGroupName == item.adGroup.name) {
                    foundAdGroup = item.adGroup.resourceName;
                }
            });
        }

        if (foundAdGroup) {
            value.adGroup = foundAdGroup;
            value.newAdGroupName = undefined;
            this.setState({ value: value });
            onMutation(value);
        }
    }

    /**
     * Value changed
     * Send to onMutation
     */
    handleAdGroupChanged = (event) => {
        const { onMutation } = this.props;

        const value = this.state.value;
        value.adGroup = event.target.value;
        this.setState({ value: value });
        onMutation(value);
    };

    /**
     * AdGroup name changed
     * @param {*} event
     */
    handleNameChanged = (event) => {
        const { onMutation } = this.props;

        const value = this.state.value;
        value.newAdGroupName = event.target.value;
        this.setState({ value: value });
        onMutation(value);
    };

    /**
     * Is Checked
     * Check whether the campaign should be selected
     * @param {*} item
     */
    campaignIsChecked = (item) => {
        const resourceName = item.campaign.resourceName;
        const value = this.state.value;
        for (const index in value) {
            if (value[index].resourceName && value[index].resourceName == resourceName) {
                return true;
            }
        }
        return false;
    };

    render() {
        const { loading, loadingAdGroups, campaignsList, value, adGroupsList } = this.state;
        const { showCampaignSelector, showAdGroupSelector, showCampaignChecklist } = this.props;

        return (
            <div className="input__google-ads">
                {loading && (
                    <div className="input__google-ads__loader">
                        <Loader />
                    </div>
                )}
                {!loading && campaignsList && showCampaignSelector && (
                    <div>
                        <Select native value={value.campaign} onChange={this.handleCampaignChanged} variant="outlined" fullWidth margin="dense">
                            <option value={''} key={'none'}>
                                None
                            </option>
                            {campaignsList &&
                                campaignsList.map((item, index) => (
                                    <option value={item.campaign.resourceName} key={index}>
                                        {item.campaign.name}
                                    </option>
                                ))}
                        </Select>
                    </div>
                )}

                {!loading && campaignsList && showCampaignChecklist && (
                    <div>
                        <FormGroup>
                            {campaignsList &&
                                campaignsList.map((item, index) => (
                                    <FormControlLabel
                                        key={'checkbox-' + index}
                                        control={
                                            <Checkbox
                                                checked={this.campaignIsChecked(item)}
                                                onChange={this.handleCampaignChecked}
                                                name={item.campaign.resourceName}
                                                value={item.campaign.resourceName}
                                            />
                                        }
                                        label={item.campaign.name}
                                    />
                                ))}
                        </FormGroup>
                    </div>
                )}

                {!loading && !campaignsList && <InlineAlert type="warning">No campaigns were found.</InlineAlert>}

                {loadingAdGroups && <Loader />}
                {showAdGroupSelector && value.campaign && campaignsList && adGroupsList && !loadingAdGroups && (
                    <div className="input__google-ads__subfield">
                        <div className="input__google-ads__label">Ad group</div>
                        <Select native value={value.adGroup} onChange={this.handleAdGroupChanged} variant="outlined" fullWidth margin="dense">
                            <option value={''} key={'none'}>
                                None
                            </option>
                            <option value="new" key="new">
                                New adgroup
                            </option>
                            {adGroupsList &&
                                adGroupsList.map((item, index) => (
                                    <option value={item.adGroup.resourceName} key={index}>
                                        {item.adGroup.name}
                                    </option>
                                ))}
                        </Select>
                    </div>
                )}

                {showAdGroupSelector && value.campaign && value.adGroup && value.adGroup == 'new' && (
                    <div className="input__google-ads__subfield">
                        <div className="input__google-ads__label">Ad group name</div>
                        <TextField
                            value={value.newAdGroupName}
                            onChange={this.handleNameChanged}
                            margin="dense"
                            variant="outlined"
                            style={{ marginTop: 0, width: '100%' }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default GoogleAds;
