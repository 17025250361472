import '../styles/main.scss';
import Close from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from 'components/ui-components-v2/TextField';
import Validator from 'components/data/Validator';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

/**
 * Link list
 * This allows you to input a list of links. These can also be clicked.
 */
export class LinkList extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func
        /** Currently active language */
    };

    static defaultProps = {
        value: [],
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        let value = [''];

        if (Array.isArray(props.value) && props.value.length > 0) {
            value = props.value;
            value.push('');
        }

        this.state = { value };
    }

    /**
     * Remove an item from the list
     */
    onRemoveClicked = (index) => {
        const { value } = this.state;
        value.splice(index, 1);
        this.setState({ value: [...value] }, this.updateModel);
    };

    /**
     * Text changes, also update source
     */
    onTextChange = (event, index) => {
        const { value } = this.state;
        const newValue = value;
        newValue[index] = event.target.value;

        // Add new empty line in case we are adding a new one to the bottom
        if (index === value.length - 1) {
            newValue.push('');
        }

        this.setState(
            {
                value: [...newValue]
            },
            this.updateModel
        );
    };

    /**
     * Handle link preview click
     */
    handleClick = (link) => {
        if (Validator.validateValue(link, { type: 'url' })) {
            window.open(link);
        } else {
            SnackbarUtils.error('The url is invalid (has to start with http:// or https://)');
        }
    };
    /**
     * Update the model
     */
    updateModel = () => {
        const { onMutation } = this.props;
        const valueList = this.state.value.filter((x) => x !== '');
        onMutation(valueList);
    };

    render() {
        const { value } = this.state;
        const { readOnly } = this.props;

        return (
            <div className="input__link-list">
                {value.map((item, index) => (
                    <span className="input__link-list__item" key={'list_' + index}>
                        {readOnly && item && <div>{item}</div>}

                        {!readOnly && (
                            <div className="input__link-list__item__wrapper">
                                <TextField
                                    fullWidth={true}
                                    key={index}
                                    variant="outlined"
                                    value={item}
                                    onChange={(event) => this.onTextChange(event, index)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => this.handleClick(item)} edge="end">
                                                    <OpenInNewIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {value.length > 1 && (
                                    <IconButton className="input__link-list__item__icon-close" onClick={() => this.onRemoveClicked(index)}>
                                        <Close />
                                    </IconButton>
                                )}
                            </div>
                        )}
                    </span>
                ))}
            </div>
        );
    }
}

export default LinkList;
