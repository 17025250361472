import React, { Component } from 'react';
import './../styles/main.scss';
import PropTypes from 'prop-types';
import { ReactTags } from 'react-tag-autocomplete';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import omit from 'lodash/omit';
import Paper from 'components/ui-components-v2/Paper';
import ListItem from 'components/ui-components-v2/ListItem';
import List from 'components/ui-components-v2/List';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Chip from 'components/ui-components-v2/Chip';
import EditorData from 'components/editor-data/EditorData';
import { withResources } from 'components/data/Resources';
/**
 * LanguageSelector
 * Allows editing the language set that is used
 */
class LanguageSelector extends Component {
    static propTypes = {
        onMutation: PropTypes.func,
        value: PropTypes.any,
        readOnly: PropTypes.bool
    };

    static defaultProps = {
        value: [],
        readOnly: false,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        const inputLanguages = props.setup.languages;
        const languages = [];
        const value = [];

        //  Set languages
        if (inputLanguages) {
            Object.keys(inputLanguages).forEach((code) => {
                languages.push({ value: code, label: code + ' - ' + inputLanguages[code] });
            });
        }

        // Set value
        if (props.value) {
            Object.keys(props.value).forEach((code) => {
                value.push({ value: code, label: code + ' - ' + inputLanguages[code] });
            });
        }

        this.state = {
            value: value,
            languages: languages
        };
    }

    /**
     * handleDelete
     * Handles the deletion of an item in the language list
     * @param {int} i The index of the item to be deleted
     */
    handleDelete = (i) => {
        const languageToRemove = this.state.value[i].value;

        const value = this.state.value.slice(0);
        value.splice(i, 1);
        this.setState({ value }, this.updateLanguages);

        // Check if current language exists
        if (languageToRemove === EditorData.getLanguage()) {
            if (value.length > 0) {
                EditorData.setLanguage(value[0].value);
            }
        }
    };

    /**
     * Handle a newly added languages
     * @param {string} item The value to be added
     */
    handleAddition = (item) => {
        const value = [].concat(this.state.value, item);
        if (value.length === 1) {
            EditorData.setLanguage(value[0].value);
        }
        this.setState({ value }, this.updateLanguages);
    };

    /**
     * Updates the languages in the data store
     */
    updateLanguages = () => {
        const value = this.state.value;
        const newValue = {};
        value.forEach((item) => {
            newValue[item.value] = item.label;
        });

        this.props.onMutation(newValue);
    };

    /**
     * Called when the props were externally updated
     */
    componentDidUpdate = (prevProps) => {
        if (prevProps.value !== this.props.value) {
            const value = [];
            Object.keys(this.props.value).forEach((code) => {
                value.push({ value: code, label: code + ' - ' + this.props.setup.languages[code] });
            });
            this.setState({ value: value });
        }
    };

    render() {
        const { languages, value } = this.state;
        const { readOnly } = this.props;

        if (readOnly) {
            return (
                <div className="input__input-language-selector">
                    {value.map((item, key) => (
                        <Chip key={key} className="react-tags__tag-name" label={item.label} />
                    ))}
                </div>
            );
        } else {
            return (
                <div className="input__input-language-selector">
                    <ReactTags
                        renderTag={({ classNames, tag, ...tagProps }) => {
                            return (
                                <Chip
                                    label={tag.label}
                                    //.react-tags__tag-name
                                    className={classNames.tagName}
                                    size="small"
                                    onDelete={() => {
                                        tagProps.onClick();
                                    }}
                                />
                            );
                        }}
                        renderListBox={({ children, classNames, ...listBoxProps }) => {
                            return (
                                <Paper className={classNames.listBox} {...listBoxProps}>
                                    <List>{children}</List>
                                </Paper>
                            );
                        }}
                        // eslint-disable-next-line no-unused-vars
                        renderOption={({ children, classNames, option, ...optionProps }) => {
                            return (
                                <ListItem dense disablePadding key={option.index} {...omit(optionProps, 'classNames')}>
                                    <ListItemButton selected={option.active}>
                                        <ListItemIcon>
                                            <Checkbox disabled={option.disabled} edge="start" checked={option.selected} disableRipple />
                                        </ListItemIcon>
                                        <ListItemText primary={children} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        }}
                        selected={value}
                        suggestions={languages}
                        placeholderText="Add language"
                        onDelete={this.handleDelete}
                        onAdd={this.handleAddition}
                    />
                </div>
            );
        }
    }
}

export default withResources(LanguageSelector, ['setup']);
