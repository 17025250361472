import '../styles/main.scss';
import TextField from 'components/ui-components-v2/TextField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Request from 'components/data/Request';
import PublishHelpers from 'components/data/PublishHelpers';
import EditorData from 'components/editor-data/EditorData';

/**
 * Google Ads Location
 * This displays the field for finding the locations in Google Ads
 */
export default class GoogleAdsLocation extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** This is a disabled object */
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : []
        };

        // Accountid
        const market = EditorData.get('market');
        if (props.googleAdsAccountByMarket) {
            this.state.accountId = 0;
            if (props.googleAdsAccountByMarket) {
                this.state.accountId = props.googleAdsAccountByMarket[market];
            }
        }
        // Account id
        else if (props.googleAdsAccount) {
            this.state.accountId = props.googleAdsAccount;
        }
        // Get from setup
        else {
            this.state.accountId = PublishHelpers.getGoogleAdsAccountId();
        }
    }

    componentDidMount() {
        // Fetch a token to connect to the publish api
        Request.post('publishing/getToken', { type: 'metadata' }).then((result) => {
            if (!result.success) {
                SnackbarUtils.error("We coulnd't connect you to the Facebook API.");
                return;
            }
            this.token = result.data.token;
        });
    }

    /**
     * Change input, this starts a timeout for fetching the data
     * @param {*} event
     */
    handleSearch = (event) => {
        this.searchTerm = event.target.value;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.requestList, 400);
    };

    /**
     * Request the result list from Facebook
     */
    requestList = () => {
        const { accountId } = this.state;
        const accountIdStripped = accountId.replace(/-/g, '');

        if (this.searchTerm === '') {
            this.setState({ searchResults: [] });
            return;
        }

        Axios.post(process.env.PUBLISH_ENGINE + 'V2/metadata/googleads/overview', {
            token: this.token,
            type: 'locations',
            search: this.searchTerm,
            customerId: accountIdStripped
        }).then((response) => {
            if (response.data && response.data) {
                this.setState({ searchResults: response.data });
            }
        });
    };

    /**
     * Get text highlights
     */
    getHighlightedText = (country, title, highlight = '') => {
        let text = '';
        if (country) {
            text = country + ': ';
        }
        if (title) {
            text = text + title;
        }

        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {' '}
                {parts.map((part, i) => (
                    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                        {part}
                    </span>
                ))}{' '}
            </span>
        );
    };

    /**
     * Add a location
     * @param {*} item
     */
    handleLocationClick = (item) => {
        const value = this.state.value;
        value.push(item);
        this.setState({ value, searchResults: [] });
        this.props.onMutation(value);
    };

    /**
     * Handle remove
     * @param {*} index
     */
    handleRemove = (index) => {
        const value = this.state.value;
        value.splice(index, 1);
        this.setState({ value, searchResults: [] });
        this.props.onMutation(value);
    };

    updateData = () => {
        this.props.onMutation(this.state.value);
    };

    render() {
        const { value = [], searchResults } = this.state;

        return (
            <div className="input__google-ads-location">
                <div className="input__google-ads-location__input">
                    <TextField onChange={this.handleSearch} style={{ marginTop: 0, width: '100%' }} margin="dense" variant="outlined" />
                </div>
                {searchResults && searchResults.map && (
                    <div className="input__google-ads-location__results">
                        {searchResults &&
                            searchResults.map((item, i) => (
                                <div className="input__google-ads-location__results__item" onClick={() => this.handleLocationClick(item)} key={'res' + i}>
                                    <div className="input__google-ads-location__results__item__name">
                                        {this.getHighlightedText(item.geoTargetConstant.countryCode, item.geoTargetConstant.name, this.searchTerm)}
                                    </div>
                                    <div className="input__google-ads-location__results__item__type">{item.geoTargetConstant.targetType}</div>
                                </div>
                            ))}
                    </div>
                )}

                {value && value.length > 0 && (
                    <div className="input__google-ads-location__list">
                        <div className="input__google-ads-location__list__title">Selected locations:</div>

                        {value &&
                            value.map((item, i) => (
                                <div className="input__google-ads-location__list__item" key={'list' + i}>
                                    <div className="input__google-ads-location__list__item__name">
                                        {this.getHighlightedText(item.geoTargetConstant.countryCode, item.geoTargetConstant.name, this.searchTerm)}
                                        <div className="input__google-ads-location__list__item__type">{item.geoTargetConstant.targetType}</div>
                                    </div>
                                    <div className="input__google-ads-location__list__item__remove" onClick={() => this.handleRemove(i)}>
                                        <IconButton size="small">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        );
    }
}
