import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * HiddenInput
 * Show a hidden field as an input
 */
export default class HiddenInput extends Component {
    static propTypes = {
        /** Function which gets called when the switch has changed */
        onMutation: PropTypes.func,
        /** Current value of the picker */
        value: PropTypes.any
    };

    static defaultProps = {
        onMutation: () => { }
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };

        if (props.parseValue && props.value != props.parseValue) {
            this.state.value = props.parseValue;
            props.onMutation(props.parseValue);
        }

        if (props.parseValueIfEmpty && !props.value) {
            this.state.value = props.parseValueIfEmpty;
            props.onMutation(props.parseValueIfEmpty);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { onMutation } = this.props;

        const newValue = this.props.parseValue;
        if (this.props.parseValue && this.state.value != newValue) {
            this.setState({ value: newValue });
            onMutation(newValue);
        }
    };

    render() {
        return <React.Fragment></React.Fragment>;
    }
}
