import LanguageSelector from './components';
import { FieldSetWrapper } from '../../input/FieldSet';

const FieldSetLanguageSelector = FieldSetWrapper(LanguageSelector);

export default LanguageSelector;
export {
    LanguageSelector,
    FieldSetLanguageSelector
}
