import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import FileUploadHandler from 'components/data/FileUpload';
import InlineAlert from 'components/ui-components/InlineAlert';
import FileListItem from 'components/assets/FileListItem';
import '../styles/main.scss';

/**
 * FileInput
 * This is an input field that is used by the multiinput blocks to upload a file. The component allows you to update various posts.
 */
class FileInput extends React.Component {
    static propTypes = {
        /** In which view the file input should be shown. */
        display: PropTypes.oneOf(['dropzone', 'inline', 'template-designer']),
        /** Can be either true or false, depending on whether you have the rights to upload files */
        canUpload: PropTypes.bool,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** Read only value */
        readOnly: PropTypes.bool,
        /** Maximum file size */
        maxFileSize: PropTypes.number,
        /** File type */
        fileType: PropTypes.string,
        /** File icon */
        fileIcon: PropTypes.string,
        /** Cypress prefix */
        dataCyPrefix: PropTypes.string
    };

    static defaultProps = {
        display: 'dropzone',
        value: [],
        canUpload: true,
        multiple: true,
        readOnly: false,
        maxFileSize: 0,
        fileType: 'all',
        onMutation: () => {},
        onSelect: () => {}
    };

    constructor(props) {
        super(props);

        this.value = props.value && Array.isArray(props.value) ? props.value : [];
        this.uploading = false;

        this.state = {
            canUpload: props.canUpload,
            selected: [],
            value: this.value
        };
    }

    componentDidUpdate(prevProps) {
        // Only handle prop changes
        if (prevProps.value === this.props.value) {
            return;
        }

        // Find differences
        if (
            (this.state.value && !this.props.value && !this.uploading) ||
            (!this.state.value && this.props.value && !this.uploading) ||
            (this.state.value &&
                this.props.value &&
                !isEqual(this.props.value, prevProps.value) &&
                !isEqual(this.state.value, this.props.value) &&
                !this.uploading)
        ) {
            const newValue = this.props.value && Array.isArray(this.props.value) ? this.props.value : [];
            this.value = newValue;
            this.setState({ value: newValue });
        }
    }

    /**
     * Called when uploading a file. All the files are pushed here.
     */
    handleFileUpload = (files, pending) => {
        const { onMutation } = this.props;
        const value = cloneDeep(this.value);
        this.uploading = pending && pending.length > 0 ? true : false;

        files.forEach((file) => {
            value.push({
                url: file.url,
                extension: file.extension,
                title: file.title,
                fileName: file.fileName,
                size: file.size,
                type: file.type
            });
        });

        this.value = value;
        this.setState({ value: value });

        // Trigger update
        onMutation(value);
    };

    /**
     * Remove item at a specific index and update the object
     */
    removeItem = (index) => {
        const value = cloneDeep(this.state.value);
        value.splice(index, 1);
        this.value = value;
        this.setState({ value: value });
        this.props.onMutation(value);
    };

    /**
     * Remove all items
     */
    removeAll = () => {
        this.value = [];
        this.setState({ value: [] });
        this.props.onMutation([]);
    };

    render() {
        const { canUpload, value } = this.state;
        const { maxFileSize, readOnly, multiple, display } = this.props;

        return (
            <div className="input__file">
                {maxFileSize > 0 && <InlineAlert type="info">The maximum file size is {maxFileSize}kb</InlineAlert>}

                {value && value.map && value.length > 3 && (
                    <div className="input__file__list-header">
                        <div className="input__file__list-header__files">{value.length} files</div>
                        <div className="input__file__list-header__remove" onClick={this.removeAll}>
                            remove all
                        </div>
                    </div>
                )}

                {value && display !== 'template-designer' && (
                    <div className="input__file__list">
                        {value.map &&
                            value.map((file, i) =>
                                canUpload && !readOnly ? (
                                    <FileListItem
                                        key={'file' + i}
                                        file={file}
                                        onRemoveItem={() => {
                                            this.removeItem(i);
                                        }}
                                    />
                                ) : (
                                    <FileListItem key={'file' + i} file={file} />
                                )
                            )}
                    </div>
                )}

                {!(!multiple && value.length) && canUpload && !readOnly && (
                    <FileUploadHandler
                        display={display}
                        fileType={this.props.fileType}
                        fileIcon={this.props.fileIcon}
                        onUploadComplete={this.handleFileUpload}
                        onRemoveItem={this.removeItem}
                        value={value}
                        size="small"
                        maxFileSize={maxFileSize}
                        dataCyPrefix={this.props.dataCyPrefix}
                    />
                )}
            </div>
        );
    }
}

export default FileInput;
