import React from 'react';

/**
 * This component takes care of writing the data to a language object on the model instead of directly to the model.
 * @param wrappedComponent The input component to wrap with the language wrapper
 * @returns The wrapped component
 */
const LanguageWrapper = (ComponentToWrap) => {
    const WrappedComponent = (props: { value: any; onMutation: (any) => void; language: string }) => {
        const { onMutation, value, language } = props;

        const handleMutation = (newValue) => {
            onMutation({ ...value, [language]: { value: newValue } });
        };

        return <ComponentToWrap {...props} onMutation={handleMutation} value={value?.[language]?.value} />;
    };

    // Set the display name for easier debugging
    const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WrappedComponent.displayName = `LanguageWrapper(${WrappedComponentName})`;

    return WrappedComponentName;
};

export { LanguageWrapper };
